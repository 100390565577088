.game {
    height: calc(100 * var(--vh, 1vh));
    width: 100%;
    display: flex;
    flex-direction: column;

    margin: 0 auto;
}

.menuIcon {
    margin-right: 1.5rem;
}

.codeRow {
    border-bottom: var(--section-divider-border) solid 0.4rem;
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
    background: var(--background);
    height: 7.4rem;
}

.guessBoard {
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 1;
    max-height: calc((100 * var(--vh, 1vh)) - (16.5rem + 7.4rem));
}

.keyboard {
    background: var(--background);
    height: 16.5rem;
}

.gameAndKeyboard {
    height: calc(100% - 7.4rem);
    display: flex;
    flex-direction: column;
}
