:root, .light {
  --color: #3e2723;
  --background: #efebe9;
  --section-divider-border: #3e2723;
  --current-row-background: #bcaaa4;
  --blank-peg-border: #d7ccc8;
  --primary-background: #3e2723;
  --primary-color: #efebe9;
  --secondary-background: #A1887F;
  --secondary-color: #3e2723;
  --link-color: #303F9F;
}

@media (prefers-color-scheme: dark){
  :root {
    --color: #efebe9;
    --background: #3e2723;
    --section-divider-border: #d7ccc8;
    --current-row-background: #6d4c41;
    --blank-peg-border: #a1887f;
    --primary-background: #d7ccc8;
    --primary-color: #3e2723;
    --link-color: #7986CB;
  }
}

.dark {
  --color: #efebe9;
  --background: #3e2723;
  --section-divider-border: #d7ccc8;
  --current-row-background: #6d4c41;
  --blank-peg-border: #a1887f;
  --primary-background: #d7ccc8;
  --primary-color: #3e2723;
  --secondary-background: #A1887F;
  --secondary-color: #3e2723;
  --link-color: #7986CB;
}

html, button, p {
  font-size: 10px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
