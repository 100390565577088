.endGame {
    display: flex;
    flex-direction: column;
}

.codeRow {
    flex-direction: row;
}

.endGame h1 {
    font-size: 3rem;
}

.endGame h2 {
    font-size: 2rem;
}

.endGame p {
    font-size: 1.6rem;
}

.buttonDiv {
    display: flex;
    margin-top: 1rem;
}

.hr {
    width: 60%;
    border: 0.15rem solid var(--section-divider-border);
}

@media (width <= 35rem) {
    .buttonDiv {
        flex-direction: column;
    }

    .button:last-child {
        margin-top: 2rem;
    }
}
