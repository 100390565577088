.keyboard {
    display: flex;
    flex-direction: column;
    border-top: var(--section-divider-border) solid 0.4rem;
    flex-wrap: wrap;
    padding: 1rem 0;
}

.duplicateString {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}

.keyboardLine {
    display: flex;
    justify-content: center;
}
