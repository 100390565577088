.peg {
    font-size: 1rem;
    height: 5rem;
    width: 5rem;
    border-radius: 1.5rem;
    margin: 0.5rem 1rem;
    display: inline-flex;
    border: none;
    border: var(--section-divider-border) 0.35em solid;
    align-items: center;
    justify-content: center;
}

.small {
    height: 2rem;
    width: 2rem;
    margin: 0.25rem;
}
