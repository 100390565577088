.pegButton {
    padding: 0;
    border: none;
    background: none;
    display: inline-flex;
    transition: all 0.2s ease;
    cursor: pointer;

    transition: filter 0.2s linear;
}

.pegButton:hover {
    filter: brightness(90%);
}

.pegButton:active {
    filter: brightness(75%);
}

.peg {
    box-shadow: 0px 2px 6px 2px var(--current-row-background);
}