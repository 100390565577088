.app {
  text-align: center;
  
  background: var(--background);
  color: var(--color);

  max-height: 100vh;
}

.app a {
  color: var(--link-color);
}