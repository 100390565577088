.button {
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem 2rem;
    border: none; 
    width: 100%;
    border-radius: 1rem;
    background: var(--primary-background);
    color: var(--primary-color);
    border: var(--primary-background) 0.2em solid;
}

.button:hover {
    filter: brightness(85%);
}

.button:active {
    filter: brightness(75%);
}

.button:disabled {
    filter: brightness(60%);
}

.secondary {
    background: inherit;
    color: var(--color);
    border-color: var(--section-divider-border);
}