.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;

    display: flex;
    align-content: center;
    justify-content: center;
    overflow: auto;
    z-index: 100;
}

.show {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.modalContent {
    background: var(--background);
    width: 60rem;
    max-width: 100%;
    height: fit-content;
    border-radius: 2rem;
    border: solid 0.2rem var(--section-divider-border);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 0;
}

.headerSpacer {
    width: 5rem;
}

.header h1 {
    font-size: 3rem;
    margin: 0;
    text-align: center;
    flex-grow: 1;
}

.modalChild {
    margin: 1rem;
}