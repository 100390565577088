.settings h1 {
    font-size: 3rem;
}

.settings h2 {
    font-size: 2rem;
}

.settings p {
    font-size: 1.6rem;
}