.switch {
    font-size: 2rem;
    display: inline-flex;
    justify-content: space-around;
    max-width: 100%;
    border-radius: 1.5rem;

    border: var(--section-divider-border) 0.2em solid;
    font-weight: bold;
}

@media (width <= 35rem) {
    .switch {
        flex-direction: column;
    }

    .noColumns {
        flex-direction: row;
    }
}

.switch input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.label {
    display: inline-flex;
    text-align: center;
    flex: 1;
    border-radius: 1.1rem;
    padding: 1rem 2rem;
    background: var(--background);
    color: var(--color);

    justify-content: center;
    align-items: center;
}

.label:hover {
    filter: brightness(85%);
}

.label:active {
    filter: brightness(75%);
}

.label p {
    margin: 0;
}

.checked {
    background: var(--primary-background);
    color: var(--primary-color);
}

.checked:active,
.checked:hover {
    filter: brightness(100%);
}
