.incrementor {
    font-size: 2rem;
    display: inline-flex;
    justify-content: space-around;
    width: fit-content;
    max-width: 100%;
    border-radius: 1.5rem;

    border: var(--section-divider-border) 0.2em solid;
    font-weight: bold;
}

.incrementor .value {
    padding: 1rem;
    margin: 0 1rem;
    min-width: 4.5rem;
}

.button {
    width: 10rem;
    border: none;
}

.hideLabel {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
