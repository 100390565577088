.iconButton {
    color: var(--section-divider-border);

    border: none;
    background: none;
    display: inline-flex;

    border-radius: 1rem;
    margin: 0.5rem;
    padding: 0;

    align-items: center;
    justify-content: center;
    align-self: center;

    cursor: pointer;
}

.iconButton:hover {
    filter: brightness(90%);
}

.iconButton:active {
    filter: brightness(80%);
}